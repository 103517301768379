/**/
@import url("https://use.typekit.net/ohg8lrj.css");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");

html {
    scroll-behavior: smooth;
}

body {
    font-size: 16px;
    color: #000;
    background-color: #f0f2f5;
    @apply font-sans antialiased;
}

.inner-underline {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 75%, #efeeff 25%);
    background-position: left 0px bottom 0.2em;
    background-repeat: no-repeat;
}

.ant-collapse {
    @apply bg-white;
}

.ant-collapse-item .ant-collapse-header {
    @apply text-lg font-medium text-blue2 !important;
}

.ant-collapse-item-active .ant-collapse-header {
    @apply text-lg font-medium text-black !important;
}

.ant-collapse-content {
    @apply border-t-0 text-xl font-normal text-blue2 !important;
}

.ant-collapse-content-box {
    @apply ml-6 pt-0 !important;
}

.ant-image-preview-img {
    display: unset;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: inherit;
}

ul {
    @apply list-disc list-inside;
}

* {
    box-sizing: border-box;
}

b {
    font-weight: 500;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 24px;
    font-weight: 400;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 14px;
}

small {
    font-size: 12px;
}

.small {
    color: #00000073;
}

/* tailwind stuff */
img {
    display: unset;
    vertical-align: unset;
    max-width: unset;
}
/* Override base SVG style to work with Ant Design */
svg {
    vertical-align: initial;
}

.ant-btn-sm {
    font-size: 12px;
}

.white-link a {
    @apply text-white;
}
