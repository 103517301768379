.dropdown {
    position: relative;
    display: inline-block;
    width: 200px; /* Adjust the width as needed */
}

.categoryHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
}

.dropdownContent {
    display: none;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    background-color: #f9f9f9; /* Replace with bg-purple-100 */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    width: 100%; /* Make the dropdown content full width */
}

.dropdownContent a {
    display: block;
    padding: 12px;
    text-decoration: none;
    color: #333;
}

.dropdownContent a:hover {
    background-color: rgb(243 232 255);
}

.dropdown:hover .dropdownContent {
    display: block;
}